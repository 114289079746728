<template>
  <div class="main" style="width: 90%; margin: auto">
    <!-- 第一张图片 -->
    <img src="./wenhua.png" class="topImg" alt="" />
    <!-- 语时....图片 -->
    <img
      src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/culture/top2Img.png"
      alt=""
      class="top2Img"
    />
    <!-- 解释 -->
    <p class="top2ImgMsg">
      此句出自萧统的《陶渊明集》序，意即：谈当下时事则有针对性而又引人深思，论抱负则旷达而且率真。
      <br />
      <br />
      旷真总裁罗迎朝进一步引申为：“指”是犀利，“想”是有深度，“怀抱”指的是胸怀，“旷而且真”即旷达而且本真。作为一名律师，在谈论时事时应当犀利而有深度，但在为人处事时又应当旷达而且本真。
      <br />
      <br />
      旷真在成立之初就鼓励所有旷真人既“旷”且“真”。“旷”即希望所有人心胸开阔、目光长远，不在乎一案一时之得失；“真”即鼓励大家能够真实真挚地表达自己的主张。
    </p>
    <!-- 线 -->
    <div class="line"></div>
    <!-- 精益法律服务 -->
    <div class="legalService">
      <h3 class="title">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/culture/shiming.png"
          alt=""
          class="icon"
        />
        <span class="titleMsg">精益法律服务</span>
      </h3>
      <p class="top2ImgMsg msg">
        从流程的角度来看，法律服务的消费其实是一个解决问题的流程，是客户在较长一段时间内采取的一系列行动，包括对法律服务的搜索、咨询、购买、合作以及最终的处置。在这个过程中，客户希望花最少的时间与精力，高效地解决问题。
        <br />
        <br />
        精益法律服务的思想源自于精益生产对于核心流程的卓越管理。
        <br />
        <br />
        从创立以来，流程化就在旷真的基因里，不断优化服务流程，提升服务品质，超出客户预期，就是旷真的使命，而精益法律服务，就是对此使命的最好概括。
      </p>
    </div>
    <!-- 线 -->
    <div class="line"></div>
    <!-- 旷真价值观 -->
    <div class="legalService">
      <h3 class="title">
        <img src="./shiming.png" alt="" class="icon" />
        <span class="titleMsg">旷真价值观</span>
      </h3>
      <!-- <p class="top2ImgMsg msg">
                旷真价值观包括
            </p>
            <p class="senseOfWorth">
                旷真所训、基本价值观、组织价值观
            </p> -->
      <h4>旷真所训</h4>
      <div class="littleBox">
        <div class="littleTitle">旷詹</div>
        <div class="littleTitle">本真</div>
        <div class="littleTitle">博学</div>
        <div class="littleTitle">明辨</div>
        <div class="littleTitle">经世</div>
        <div class="littleTitle">致公</div>
      </div>
      <!-- <h4>基本价值观</h4>
            <div class="littleBox">
                <div class="littleTitle">
                    07 &nbsp;客户中心
                </div>
                <div class="littleTitle">
                    08 &nbsp;价值创造
                </div>
                <div class="littleTitle">
                    09 &nbsp;团队合力
                </div>
                <div class="littleTitle">
                    10 &nbsp;魅力品质
                </div>
            </div> -->
      <h4>价值观</h4>
      <div class="littleBox">
        <div class="littleTitle">客户中心</div>
        <div class="littleTitle">结果导向</div>
        <div class="littleTitle">持续改善</div>
        <div class="littleTitle">长期奋斗</div>
        <div class="littleTitle">本真求是</div>
      </div>
      <!-- <h4>组织价值观</h4>
            <div class="littleBox">
                <div class="littleTitle">
                    16 &nbsp;开拓进取
                </div>
                <div class="littleTitle">
                    17 &nbsp;本真求是
                </div>
                <div class="littleTitle">
                    18 &nbsp;理性思考
                </div>
                <div class="littleTitle">
                    19 &nbsp;积极服从
                </div>
                <div class="littleTitle">
                    20 &nbsp;高效执行
                </div>
                <div class="littleTitle">
                    21 &nbsp;帮助他人
                </div>
                <div class="littleTitle">
                    22 &nbsp;持续学习
                </div>
            </div> -->
    </div>
    <!-- 线 -->
    <div class="line"></div>
    <!-- 短期愿景 -->
    <div class="legalService" style="margin-top: -0.5rem">
      <h3 class="title">
        <!-- <span class="titleMsg shortYuanjing">短期愿景</span> -->
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/culture/yuanjing.png"
          alt=""
          class="icon"
        />
      </h3>
      <p class="top2ImgMsg msg">
        旷真致力打造有学习力、凝聚力和奋斗精神的组织；
      </p>
      <!-- <h3 class="title">
                <span class="titleMsg shortYuanjing">中期愿景</span>
            </h3> -->
      <p class="top2ImgMsg msg">创造客户新的价值体验，创造员工发展机会；</p>
      <!-- <h3 class="title">
                <span class="titleMsg shortYuanjing">长期愿景</span>
            </h3> -->
      <p class="top2ImgMsg msg">引领法律行业变革，贡献法治社会，成就伟大。</p>
    </div>
    <!-- 线 -->
    <div class="line"></div>
    <div class="legalService">
      <h4 class="title">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/culture/tese.png"
          class="icon"
          alt=""
        />
        <span class="titleMsg" style="margin-left: 0.2rem"
          >旷真之路：产品化、组织化、规模化</span
        >
      </h4>
      <h4>旷真之乘：管理者与专家的合伙</h4>
      <p class="watchMsg" @click="watchMsg">
        查看详细解读 <i class="arrowIcon"></i>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    watchMsg() {
      this.$router.push("/newsMsg?id=" + 154);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 90%;
  margin: 0.5rem auto;

  .topImg {
    display: block;
    width: 100%;
    margin: 1rem auto;
  }

  .top2Img {
    width: 50%;
  }

  .top2ImgMsg {
    color: #666666;
    margin-top: 0.5rem;
  }

  .line {
    width: 100%;
    margin: 1rem auto;
    height: 0.05rem;
    background-color: #e8e8e9;
  }

  .legalService {
    width: 100%;
    margin: auto;

    .title {
      position: relative;
      width: 100%;
      height: 1rem;
      line-height: 1rem;

      .icon {
        width: 1rem;
      }
    }

    .senseOfWorth {
      color: #c7000a;
      font-weight: 700;
    }

    .littleBox {
      overflow: hidden;

      .littleTitle {
        float: left;
        width: 22%;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        margin-right: 3%;
        background: rgba(171, 0, 0, 0.03);
        border-radius: 0.1rem;
        margin-bottom: 0.2rem;
        color: #333;
        font-family: PingFangSC-Medium, PingFang SC;
      }
    }

    .shortYuanjing {
      font-size: 0.3rem;
    }
  }

  .kuangZhenRoad {
    padding-bottom: 1rem;

    .hasImg {
      position: relative;
      // padding-left: 1.2rem;
      .teseImg {
        position: absolute;
        left: 0rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
      }
    }

    .watchMsg {
      position: relative;
      height: 0.3rem;
      line-height: 0.3rem;
      color: #999999;

      .showMore {
        position: absolute;
        width: 0.5rem;
        top: 50%;
        left: 30%;
        transform: translateX(-50%) translateY(-54%);
        line-height: 3rem;

        i {
          position: absolute;
          top: 50%;
          right: 0;
          border: solid #fa6e23;
          border-width: 0 0.1rem 0.1rem 0;
          display: inline-block;
          padding: 3px;
          box-sizing: border-box;
        }

        .right {
          transform: rotate(-45deg) translateY(-60%);
          -webkit-transform: rotate(-45deg) translateY(-60%);
        }
      }

      .showMore::after {
        position: absolute;
        top: 50%;
        left: 0%;
        content: "";
        height: 0.1rem;
        width: 0.4rem;
        background-color: #fa6e23;
      }
      .arrowIcon {
        position: relative;
        top: 0.05rem;
        color: #fa6e23;
      }
    }
  }
}
</style>
